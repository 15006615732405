import React, { useEffect, useRef, useState } from "react";
import { MobileNumber } from "./utils/MobileNumber";
import {
  InputFieldJob,
  InputWithDropdown,
  InputWithleft,
  InputWithRight,
} from "./utils/InputComponent";
import ReCAPTCHA from "react-google-recaptcha";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CalenderIcon } from "../assets/common/Icons/icons";
import { ReactComponent as ArrowIcon } from "../assets/common/svgs/Arrow.svg";
import { SubmitJobForm } from "../api/apis";
import { Alert } from "@material-tailwind/react";
import { useOnClickOutside } from "../utils/OutsideClick";

const JobForm = ({ technology }) => {
  const recaptchaRef = useRef(null);
  const initialValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    experienceYears: "",
    experienceMonths: "",
    gender: "",
    currentSalary: "",
    expectedSalary: "",
    availableToJoin: "",
    preferredLocation: "",
  };
  const [recaptcha, setRecaptcha] = useState("");
  const [userDetails, setUserDetails] = useState(initialValues);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cCodd, setCCode] = React.useState("+91");
  const [file, setFile] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    // Check required fields
    Object.keys(userDetails).forEach((key) => {
      if (
        !userDetails[key] &&
        key !== "Resume" &&
        key !== "technology" &&
        key !== "currentSalary" &&
        key !== "expectedSalary" &&
        key !== "availableToJoin" &&
        key !== "preferredLocation" && 
        key !== "experienceMonths"
      ) {
        tempErrors[key] = `${key} is required`;
        isValid = false;
      }
    });

    if (userDetails.email && !validateEmail(userDetails.email)) {
      tempErrors.email = "Invalid email format";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber") {
      if (
        /^[0-9]*$/.test(e.target.value) &&
        userDetails.phoneNumber.length < 11
      ) {
        setUserDetails({
          ...userDetails,
          [name]: value,
        });
      }
    } else if (name === "experienceYears") {
      if (/^[0-9]*$/.test(value) && value.length <= 2) {
        setUserDetails({
          ...userDetails,
          [name]: value,
        });
      }
    } else if (name === "experienceMonths") {
      if (value === "" || (value >= 0 && value <= 12)) {
        setUserDetails({
          ...userDetails,
          [name]: value,
        });
      }
    } else if (
      name === "availableToJoin" ||
      name === "currentSalary" ||
      name === "expectedSalary"
    ) {
      if (/^[0-9]*$/.test(value)) {
        setUserDetails({
          ...userDetails,
          [name]: value,
        });
      }
    } else {
      setUserDetails({
        ...userDetails,
        [name]: value,
      });
    }
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleClick = (name, value) => {
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const isValid = validate();

    if (isValid) {
      const day = String(startDate?.getDate()).padStart(2, "0");
      const month = String(startDate?.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = startDate?.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      const formData = new FormData();

      // Append all user details to formData
      for (const key in userDetails) {
        formData.append(key, userDetails[key]);
      }

      // Append the file to formData
      if (file) {
        formData.append("Resume", file);
      }
      formData.append("technology", technology);
      formData.append("dateOfBirth", formattedDate);
      setIsLoading(true);
      const res = await SubmitJobForm(recaptcha, formData);
      if (res.status === 200) {
        setIsLoading(false);
        setShow(true);
        setUserDetails(initialValues);
        setFile();
        recaptchaRef.current.reset();
      } else {
        setIsLoading(false);
        recaptchaRef.current.reset();
      }
    } else {
      console.log("Validation Failed", errors);
    }
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  useEffect(() => {
    if (
      userDetails.fullName &&
      userDetails.email &&
      // userDetails.experienceMonths &&
      userDetails.experienceYears &&
      userDetails.gender &&
      userDetails.phoneNumber &&
      file &&
      recaptcha
    ) {
      setIsAllowSubmit(true);
    } else {
      setIsAllowSubmit(false);
    }
  }, [
    userDetails.fullName,
    userDetails.email,
    // userDetails.experienceMonths,
    userDetails.experienceYears,
    userDetails.phoneNumber,
    userDetails.gender,
    file,
    recaptcha,
  ]);

  const [showCalendar, setShowCalendar] = useState(false); // State for calendar visibility
  const outsideRef = useRef();
  useOnClickOutside(outsideRef, () => setShowCalendar(false));
  // Handle date change from calendar
  const handleDateChange = (selectedDate) => {
    setStartDate(selectedDate);
    setShowCalendar(false); // Hide calendar after date selection
  };
  return (
    <div className="py-[120px] max_sm:py-[60px]">
      <div className="file-upload-container">
        <div className="upload-box">
          <label htmlFor="fileUpload" className="file-label">
            <strong className="max_sm:text-PageSeperater">Upload resume</strong>{" "}
            <br />
            <p className="mt-[10px] max_sm:mt-[5px] max_sm:text-SmallSize">
              Max size 10 MB
            </p>
          </label>
          <input
            id="fileUpload"
            type="file"
            className="file-input"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.jpg,.png"
          />
        </div>

        {file && (
          <div className="file-details flex justify-between">
            <p>
              <strong>File Name:</strong> {file.name}
            </p>
            <p className="cursor-pointer" onClick={() => setFile()}>
              X
            </p>
          </div>
        )}
      </div>
      <div className="mt-[60px] grid grid-cols-2 gap-5 max_md:grid-cols-1 max_sm:mt-5">
        <InputFieldJob
          type="text"
          label="Full Name*"
          placeholder="Full Name"
          name="fullName"
          className="relative"
          onChange={(e) => handleOnChange(e)}
          value={userDetails.fullName}
          error={errors && errors.fullName && "Please enter name"}
        />
        <InputFieldJob
          type="text"
          label="Email address*"
          placeholder="Email address"
          name="email"
          className="relative"
          onChange={(e) => handleOnChange(e)}
          value={userDetails.email}
          error={errors && errors.email && "Please enter valid email address"}
        />
        <InputWithDropdown
          label="Gender*"
          placeholder=""
          name="gender"
          className="relative"
          handleClick={handleClick}
          value={userDetails.gender}
          list={["Male", "Female"]}
          // error={isFnameFoucs && !isFnameValid && "Please enter name"}
        />

        <MobileNumber
          cCodd={cCodd}
          setCCode={setCCode}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          handleOnChange={handleOnChange}
          value={userDetails.phoneNumber}
        />

        <div className="h-[50px] w-full max_sm:h-[38px]">
          <p className="">Date of birth</p>
          <div className="relative z-40">
            <input
              style={{
                WebkitAppearance: "none",
              }}
              value={startDate ? startDate.toLocaleDateString() : ""}
              onFocus={(e) => {
                e.stopPropagation();
                setShowCalendar(!showCalendar);
              }} // Show calendar when input is focused
              readOnly
              placeholder="Select a date"
              className="focus-visible:none mt-[9px] h-[50px] w-full rounded-[8px] bg-moon-white px-[24px] text-[17px] font-medium text-duskwood focus:outline-none max_xl:px-[16px] max_md:text-[14px] max_sm:h-[38px]"
            />
            <div
              className="absolute right-3 top-5 z-20 max_sm:top-[14px]"
              onClick={(e) => {
                e.stopPropagation();
                setShowCalendar(!showCalendar);
              }}
            >
              <CalenderIcon />
            </div>
            {/* Conditionally render the calendar */}
            {showCalendar && (
              <div className="z-40 flex w-max" ref={outsideRef}>
                <Calendar
                  onChange={handleDateChange}
                  value={startDate}
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5 max_md:mt-7 max_sm:mt-10">
          <InputWithRight
            type="text"
            label="Experience*"
            placeholder="Years"
            name="experienceYears"
            className="relative"
            onChange={(e) => handleOnChange(e)}
            value={userDetails.experienceYears}
          />
          <InputWithRight
            type="text"
            label=""
            placeholder="Months"
            name="experienceMonths"
            className="relative"
            onChange={(e) => handleOnChange(e)}
            value={userDetails.experienceMonths}
          />
        </div>

        <InputWithleft
          label="Current Salary"
          placeholder=""
          name="currentSalary"
          className="relative"
          onChange={(e) => handleOnChange(e)}
          value={userDetails.currentSalary}
        />
        <InputWithleft
          label="Expected Salary"
          placeholder=""
          name="expectedSalary"
          className="relative"
          onChange={(e) => handleOnChange(e)}
          value={userDetails.expectedSalary}
        />

        <InputFieldJob
          type="text"
          label="Available To Join (in days)"
          placeholder="10"
          name="availableToJoin"
          className="relative"
          onChange={(e) => handleOnChange(e)}
          value={userDetails.availableToJoin}
          // error={isFnameFoucs && !isFnameValid && "Please enter name"}
        />

        <InputWithDropdown
          label="Preferred Location"
          placeholder=""
          name="preferredLocation"
          value={userDetails.preferredLocation}
          handleClick={handleClick}
          list={["Katargam", "Adajan"]}
        />
      </div>

      <div className="relative mt-[60px] flex items-center gap-10 max_sm:flex-col">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LeTjYclAAAAAB9_gSbhJ5wlQwLgecf2qiEKTXE2"
          onChange={(value) => {
            setRecaptcha(value);
          }}
          onExpired={() => {
            setRecaptcha("");
            recaptchaRef.current.reset();
          }}
        />

        <div>
          <div
            onClick={() => {
              if (!isLoading && isAllowSubmit) {                
                handleSubmit();
              }
            }}
            className={`F-JC-AI-CENTER w-[176px] gap-x-[10px] rounded-ButtonRadius bg-bateau px-[31px] py-[14px] ${
              !isAllowSubmit || isLoading
                ? "cursor-not-allowed opacity-70"
                : "cursor-pointer"
            }`}
          >
            {isLoading ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="h-6 w-6 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                <div className="F-JC-AI-CENTER text-MediumSize font-semibold text-white ">
                  Apply now
                </div>
                <ArrowIcon />
              </>
            )}
          </div>
        </div>
        <Alert
          show={show}
          color="green"
          dismissible={{
            onClose: () => setShow(false),
          }}
          className="absolute bottom-full left-0 max-w-[605px] max_xl:max-w-[460px] max_sm:bottom-[50%]"
        >
          Your request was successfully submitted.
        </Alert>
      </div>
    </div>
    // </div >
  );
};

export default JobForm;
